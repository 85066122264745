<template>
    <div class="col-right">
        <a-card title="安全中心">
            <div class="fun-list">
                <dl>
                    <dd>
                        <ul class="clearfix">
                            <li>
                                <div class="item-icon">
                                    <img src = "../../assets/img/icons/icon-lock.png" alt = "">
                                </div>
                                <div class="item-title">支付密码</div>
                                <div class="item-desc">支付商品或服务订单时使用，注意密码保管</div>
                                <div class="item-btn">
                                    <a-button style="width: 110px;" @click="setPayPassword">修改密码</a-button>
                                </div>
                            </li>
                        </ul>
                    </dd>
                </dl>
                <div class="warn-tips" v-if="checkPayPassword">
                    <a-icon type="check-circle" theme="filled" style="color: #87d068;" />
                    <div class="item-text">已设置</div>
                </div>
            </div>
        </a-card>
    </div>
</template>

<script>
import service from "../../../utils/request";

export default {
    name: "setting-safety",
    data(){
        return {
            checkPayPassword: false,
        }
    },
    created() {
        this.hasPayPassword();
    },
    methods:{
        hasPayPassword(){
            service.post(service.uri.user.hasPayPassword).then(res => {
                if(res.code == 200){
                    this.checkPayPassword = res.data;
                }
            })
        },
        setPayPassword(){
            this.$router.push('/buyer-detail/buyer-setting-payment-password')
        },
    }
}
</script>

<style lang="scss" scoped>
.fun-list{
    padding: 90px 0;
    dl{
        dd{
            display: flex;
            justify-content: center;
            li{
                width: 570px;
                height: 323px;
                background: #fff;
                box-shadow: 0 2px 8px 0 hsl(0deg 0% 86% / 50%);
                box-sizing: border-box;
                padding: 50px 10px 10px;
                position: relative;
                .item-icon {
                    display: block;
                    margin: 0 auto;
                    width: 58px;
                    height: 58px;
                    background: #ff9f9f;
                    border-radius: 50%;
                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
                .item-title {
                    text-align: center;
                    font-size: 16px;
                    color: #333;
                    line-height: 22px;
                    padding: 12px 0 10px;
                }
                .item-desc {
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    color: #999;
                    height: 36px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .item-btn {
                    text-align: center;
                    margin: 0 auto;
                    padding-top: 40px;
                }
            }
        }
    }
    .warn-tips {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        .item-text {
            color: #999;
            font-size: 14px;
            padding-left: 12px;
            line-height: 24px;
        }
    }
}
</style>